import { Box, Grid, Paper, useTheme } from "@mui/material";
import { Link } from "../../models/CheckoutLink";
import { connect } from "react-redux";
import { IAppState } from "../../redux/storeTypes";

export interface IFooterProps {
    link: Link;
}

const Footer = ({ link }: IFooterProps) => {
    const theme = useTheme();
    return (
        <Paper>
            <Grid container>
                <Grid item xs>
                    <Box component="div" textAlign={'center'} color={theme.palette.text.secondary} fontWeight={'fontWeightLight'} fontSize={'0.8em'}>Powered by MSB</Box>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs>
                    <Box component="div" textAlign={'center'} color={theme.palette.text.secondary} fontWeight={'fontWeightLight'} fontSize={'0.7em'}>
                        By using Link, you agree to accept our&nbsp;
                        <a href="https://payments.msbpay.navient.com/Privacy_Policy.pdf" target={"_blank"} rel={"noreferrer"}>Privacy Policy</a>,&nbsp;
                        <a href="https://payments.msbpay.navient.com/Terms_Of_Use.pdf" target={"_blank"} rel={"noreferrer"}>Terms of Use</a>&nbsp;and&nbsp;
                        <a href="https://payments.msbpay.navient.com/Payment_Terms_and_Conditions.pdf" target={"_blank"} rel={"noreferrer"}>Terms & Conditions</a>
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

const mapStateToProps = (state: IAppState) => {
    return {
        link: state.link.link,
   };
};

export default connect(mapStateToProps)(Footer);